import React from 'react';
import image from './backs.jpeg'
import './App.css';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-6040917-4');
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>
          I love Jennifer Oxenford
        </h1>
        <img src={image} className="App-image" alt="logo" />
      </header>
    </div>
  );
}

export default App;
